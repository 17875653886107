import { useState } from "react";
import './epc.css'
function Epc() {
 

  return (
    <>
         <div className='card' style={{width:"101%"}}>
                    <div className='epcimgTxt'>
                        <div className='epcimgtxtBack'>
                            <h2 className="mt-2" style={{ fontFamily: 'calibiri', fontWeight: '600' }}>EPC PROJECTS </h2><br />
                        </div>
                    </div>
                </div>
{/*  */}
<div><h1 className="text-center mt-2"><b>PROJECT HIGHLIGHTS</b></h1></div>

      <div className="epccard-container">
        {/* Company 1 Card */}
        <div className="epccard">
        
          <div className="epccard-body1">
          <div className="namecompany" >
          <div  className="epccard-header text-center fs-3 lh-1 pt-1">Saatvik</div>

            <p className="text-center fs-5 lh-1 pt-2">Naraiana, Jaipur, Rajasthan</p>
            <p className=" text-center fs-6 lh-1">3.021 MWp</p>
          </div>
          </div>
        </div>

        {/* Company 2 Card */}
        <div className="epccard">
          <div className="epccard-body2">
          <div className="namecompany">
          <div className="epccard-header text-center fs-3 lh-1 pt-1">Jakson </div>

            <p className="text-center fs-5 lh-1 pt-2 ">Pullur, Telangna</p>
            <p className="text-center fs-6 lh-1"> 237Kwp </p>
          </div>
        </div></div>
        </div>
<div className="epccard-container">
        {/* Company 3 Card */}
        <div className="epccard">
          <div className="epccard-body3">
          <div className="namecompany">
          <div className="epccard-header text-center  fs-3 lh-1 pt-1"> KPI Green Energy Ltd</div>

            <p className="text-center fs-5 lh-1 pt-2"> Netrang, Bharuch, Gujarat</p>
            <p className="text-center fs-6 lh-1"> 3.4Mwp </p>
          </div></div>
        </div>
{/* 4 */}
        <div className="epccard  text-center">
          <div className="epccard-body4">
          <div className="namecompany">

          <div className="epccard-header  fs-5 lh-1 pt-1"> MEIL - Megha Engineering & Infrastructure Ltd.</div>

            <p className="text-center fs-5 lh-1 pt-2">Lohgaon,  Nanded, Maharashtra</p>
            <p  className="text-center fs-6 lh-1">  5.1Mwp </p>
          </div>
        </div></div>
              
        </div>
    </>
  );
}

export default Epc;
