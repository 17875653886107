import { useEffect } from 'react';
import './Project.css'
import { useState } from 'react';

function Project() {
  const [deploy, setDeploy] = useState(1800);
  const [statect, setStatect] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);

  const images = [
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj1.jpg',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj2.jpg',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj3.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj4.jpg'
  ];

  const showSlide = (n) => {
    setSlideIndex((n + images.length) % images.length);
  };

  const nextSlide = () => {
    showSlide(slideIndex + 1);
  };

  const prevSlide = () => {
    showSlide(slideIndex - 1);
  };

// next img
const [slideIndex1, setSlideIndex1] = useState(0);

  const images1 = [
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/Screenshot+2024-12-11+163357.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/hry1.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20210210_164001.jpg',
    
  ];

  const showSlide1 = (n) => {
    setSlideIndex1((n + images1.length) % images1.length);
  };

  const nextSlide1 = () => {
    showSlide1(slideIndex1 + 1);
  };

  const prevSlide1 = () => {
    showSlide1(slideIndex1 - 1);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
      nextSlide1();
      nextSlide2();
      nextSlide3();
      nextSlide4();
      nextSlide5();
      nextSlide6();
    }, 2000);
    return () => clearInterval(timer);
  });
// 2 
// next img
const [slideIndex2, setSlideIndex2] = useState(0);

  const images2 = [
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/mahar.png',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20210310_140936_1.jpg',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/guj.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/maha.png',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/IMG_20210618_130819.jpg',
  ];

  const showSlide2 = (n) => {
    setSlideIndex2((n + images2.length) % images2.length);
  };

  const nextSlide2 = () => {
    showSlide2(slideIndex2 + 1);
  };

  const prevSlide2 = () => {
    showSlide2(slideIndex2 - 1);
  };


// next img
const [slideIndex3, setSlideIndex3] = useState(0);

  const images3 = [
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20210210_164001.jpg',

'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Screenshot+2024-12-11+155208.png',
   ];

  const showSlide3 = (n) => {
    setSlideIndex3((n + images3.length) % images3.length);
  };

  const nextSlide3 = () => {
    showSlide3(slideIndex3 + 1);
  };

  const prevSlide3 = () => {
    showSlide3(slideIndex3 - 1);
  };


//   4
const [slideIndex4, setSlideIndex4] = useState(0);

  const images4 = [
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/hryy.png',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/hry1.png',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/hry.png',
  
  ];

  const showSlide4 = (n) => {
    setSlideIndex4((n + images4.length) % images4.length);
  };

  const nextSlide4 = () => {
    showSlide4(slideIndex4 + 1);
  };

  const prevSlide4 = () => {
    showSlide4(slideIndex4 - 1);
  };

// 5
const [slideIndex5, setSlideIndex5] = useState(0);

  const images5 = [
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2024-05-01+at+16.11.20.jpeg',
    'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20211226_123418.jpg',
  
  ];

  const showSlide5 = (n) => {
    setSlideIndex5((n + images5.length) % images5.length);
  };

  const nextSlide5 = () => {
    showSlide5(slideIndex5 + 1);
  };

  const prevSlide5 = () => {
    showSlide5(slideIndex5 - 1);
  };

//   6
const [slideIndex6, setSlideIndex6] = useState(0);

  const images6 = [
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/20210613_135534.jpg',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/rj1.png',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20210210_164001.jpg',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/rj.png',
'https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/IMG_20211226_122914.jpg'  ];

  const showSlide6 = (n) => {
    setSlideIndex6((n + images6.length) % images6.length);
  };

  const nextSlide6 = () => {
    showSlide6(slideIndex6 + 1);
  };

  const prevSlide6 = () => {
    showSlide6(slideIndex6 - 1);
  };

 
// 

  useEffect(() => {
    const deployInterval = setInterval(() => {
        setDeploy(prevDeploy => {
          if (prevDeploy < 1987) {
            return prevDeploy + 1;
          } else {
            clearInterval(deployInterval);
            return prevDeploy;
          }
        });
      }, 30);
  
      const statectInterval = setInterval(() => {
        setStatect(prevStatect => {
          if (prevStatect < 18) {
            return prevStatect + 1;
          } else {
            clearInterval(statectInterval);
            return prevStatect;
          }
        });
      }, 120);
     
  
      // Cleanup intervals on component unmount
      return () => {
        clearInterval(deployInterval);
        clearInterval(statectInterval);
      
      };
  }, []);
    return (
        <>
          
                <div className='card' style={{width:"101%"}}>
                    <div className='imgTxt'>
                        <div className='imgtxtBack'>
                            <h2 style={{ fontFamily: 'calibiri', fontWeight: '600' }}>Our Projects</h2><br />
                            <p style={{ color: '#0e1c4a' }}>At Skilancer Solar, we are pioneering the future of clean energy through inNo.vative solar solutions. Our mission is to harness the power of the sun to create a sustainable and energy-efficient world. We believe that solar energy is the key to a greener future, and we are committed to making it accessible and efficient for everyone.</p>
                        </div>
                    </div>
                </div>


                <br />
                <div className="d-flex justify-content-around ">
    <div className="box1dep col-lg-5 ">
        <div className=""><h1 className='ph1 text-center mt-3'>Deployments</h1>  </div>
        <div className="text-center"><h2 className=' text-danger mt-3'>+{deploy} MW</h2> </div>
      
    </div>
<div className="box1dep col-lg-5 ms-2 ">
    <div className=""><h1 className='ph1 text-center mt-3'>States</h1>  </div>
        <div className="text-center"><h2 className='text-danger mt-3' >+{statect}</h2></div>
      
</div>
</div>

      <br />
            {/* new */}
            <div className="card mb-3 " style={{ backgroundColor: 'rgb(206, 220, 226' }} id='gujarat'>
                <h2 className='stateHeading'>Gujarat</h2>
                <div className="row g-0" >

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample1">
                                <div className="accordion-item"  >
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                            Adani Hazira Port Limited
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Surat</p>
                                            <p>Plant Capacity: 3MW </p>
                                          
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading2">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            Adani Wilmar limited
                                        </button>
                                    </h2>
                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Surat</p>
                                            <p>Plant Capacity: 1 MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Landmark
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Ahmedabad</p>
                                            <p>Plant Capacity: 129KW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" style={{ backgroundColor: 'No.ne' }}>
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-controls="collapseFour" >
                                            High urja
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Surat</p>
                                            <p>Plant Capacity: 24MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Chattle Construction
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Surat</p>
                                            <p>Plant Capacity: 25MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Renew Power
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Patan</p>
                                            <p>Plant Capacity: 105MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingseven">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                         Prozeal Green Energy Limited
                                        </button>
                                    </h2>
                                    <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            <p>Location: Somnath</p>
                                            <p>Plant Capacity: 3.9MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide}>
          &#10095;
        </button>

        <div className="dots-container">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex ? 'active' : ''}`}
              onClick={() => showSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>
            <br />

            {/* Telangana*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} >
                <h2 className='stateHeading'>Telangana</h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample2">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingEleven">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven" >
                                            Premier Energy Photovoltaic
                                        </button>
                                    </h2>
                                    <div id="collapseEleven" className="accordion-collapse collapse " aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">

                                            <p>Location: Hyderabad</p>
                                            <p>Plant Capacity: 6MW </p>
                                            
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading12">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">

                                            AWL Mantralayam
                                        </button>
                                    </h2>
                                    <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>Location: Mantralayam</p>
                                            <p>Plant Capacity: 1MW </p>
                                             <br />


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading13">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                            AWL Krishnapatnam
                                        </button>
                                    </h2>
                                    <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="headingThr" data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">

                                            <p>Location: Krishnapatnam</p>
                                            <p>Plant Capacity: 134.82KW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" style={{ backgroundColor: 'None' }}>
                                    <h2 className="accordion-header" id="heading14">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-controls="collapse14" >
                                            Artha Vijayvaada
                                        </button>
                                    </h2>
                                    <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>Location: Vijayvaada</p>
                                            <p>Plant Capacity: 250KW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading15">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                            Bequer Parkson
                                        </button>
                                    </h2>
                                    <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>Location: Tirupati</p>
                                            <p>Plant Capacity: 1000KW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                       {/* Slideshow Section */}
     <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images1.map((image1, index) => (
          <img
            key={index}
            src={image1}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex1 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide1}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide1}>
          &#10095;
        </button>

        <div className="dots-container">
          {images1.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex1 ? 'active' : ''}`}
              onClick={() => showSlide1(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>

            <br />

            {/* Maharashtra*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} >
                <h2 className='stateHeading'>Maharashtra</h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample3">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading116">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="true" aria-controls="collapse16" >
                                            O2 Power
                                        </button>
                                    </h2>
                                    <div id="collapse16" className="accordion-collapse collapse " aria-labelledby="heading16" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">
                                            <p>Location: Georai</p>
                                            <p>Plant Capacity:  25MW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading17">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                            Glaxo Smithkline Pharmaceuticals Ltd.
                                        </button>
                                    </h2>
                                    <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">
                                            <p>Location: Nasik</p>
                                            <p>Plant Capacity:  1MW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading18">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse3">
                                            ITC Pune
                                        </button>
                                    </h2>
                                    <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">

                                            <p>Location: Pune</p>
                                            <p>Plant Capacity: 0.5MW  </p>
                                            <br />


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" style={{ backgroundColor: 'No..ne' }}>
                                    <h2 className="accordion-header" id="heading19">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-controls="collapse19" >
                                            Avaada Clean Project Private Limited
                                        </button>
                                    </h2>
                                    <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">
                                            <p>Location: Yavatmal</p>
                                            <p>Plant Capacity: 100MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading20">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                            Enerparc Jalgaon
                                        </button>
                                    </h2>
                                    <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">
                                            <p>Location: Jalgaon</p>
                                            <p>Plant Capacity: 4.5MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading21">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                            Bequer Pune
                                        </button>
                                    </h2>
                                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample3">
                                        <div className="accordion-body">
                                            <p> Location: Pune</p>
                                            <p> Plant Capacity: 1000KW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                     {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images2.map((image2, index) => (
          <img
            key={index}
            src={image2}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex2 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide2}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide2}>
          &#10095;
        </button>

        <div className="dots-container">
          {images2.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex2 ? 'active' : ''}`}
              onClick={() => showSlide2(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>

            <br />

            {/* West Bengal*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} >
                <h2 className='stateHeading'>West Bengal</h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample4">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading22">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="true" aria-controls="collapse22" >
                                           Gewis
                                        </button>
                                    </h2>
                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample4">
                                        <div className="accordion-body">
                                            <p>Location: Haldia</p>
                                            <p>Plant Capacity: 1MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            
                            {/* next */}

                           
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading222">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse222" aria-expanded="true" aria-controls="collapse222" >
                                        Alligator Energies Pvt Ltd
                                        </button>
                                    </h2>
                                    <div id="collapse222" className="accordion-collapse collapse" aria-labelledby="heading222" data-bs-parent="#accordionExample4">
                                        <div className="accordion-body">
                                            <p>Location: Haldia</p>
                                            <p>Plant Capacity: 650KW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                       {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images3.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex3 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide3}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide3}>
          &#10095;
        </button>

        <div className="dots-container">
          {images3.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex3 ? 'active' : ''}`}
              onClick={() => showSlide3(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>

            {/* Haryana*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} >
                <h2 className='stateHeading'>Haryana</h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample5">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading23">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="true" aria-controls="collapse23" >
                                            Denso
                                        </button>
                                    </h2>
                                    <div id="collapse23" className="accordion-collapse collapse " aria-labelledby="heading23" data-bs-parent="#accordionExample5">
                                        <div className="accordion-body">
                                            <p>Location: Manesar</p>
                                            <p>Plant Capacity: 1MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading24">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                                            Hero Motocorp
                                        </button>
                                    </h2>
                                    <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample5">
                                        <div className="accordion-body">
                                            <p>Location: Dharuhera</p>
                                            <p>Plant Capacity: 6MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading25">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                                            HUL Sonipat
                                        </button>
                                    </h2>
                                    <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExample5">
                                        <div className="accordion-body">
                                            <p>Location: Sonipat</p>
                                            <p>Plant Capacity: 1MW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" style={{ backgroundColor: 'No..ne' }}>
                                    <h2 className="accordion-header" id="heading26">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-controls="collapse26" >
                                            Wave Aircom
                                        </button>
                                    </h2>
                                    <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="heading26" data-bs-parent="#accordionExample5">
                                        <div className="accordion-body">
                                            <p>Location:Bhiwadi </p>
                                            <p>Plant Capacity: .5MW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading27">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                                            Clean Teach
                                        </button>
                                    </h2>
                                    <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample5">
                                        <div className="accordion-body">
                                            <p>Location: Sirsa</p>
                                            <p>Plant Capacity: 15MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                      {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images4.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex4 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide4}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide4}>
          &#10095;
        </button>

        <div className="dots-container">
          {images4.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex4 ? 'active' : ''}`}
              onClick={() => showSlide4(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>

            {/* Tamil Nadu*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} >
                <h2 className='stateHeading'>Tamil Nadu </h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample6">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading29">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="true" aria-controls="collapse29" >
                                            Continuum
                                        </button>
                                    </h2>
                                    <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExample6">
                                        <div className="accordion-body">
                                            <p>Location: Coimbatore</p>
                                            <p>Plant Capacity: 78MW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading30">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
                                            Enerparc Tamilnadu
                                        </button>
                                    </h2>
                                    <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30" data-bs-parent="#accordionExample6">
                                        <div className="accordion-body">
                                            <p>Location: Madhurai</p>
                                            <p>Plant Capacity:  33MW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                       {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images5.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex5 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide5}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide5}>
          &#10095;
        </button>

        <div className="dots-container">
          {images5.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex5 ? 'active' : ''}`}
              onClick={() => showSlide5(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>

            {/* Rajasthan*/}

            <div className="card mb-3" style={{ backgroundColor: 'rgb(206, 220, 226' }} id='rajasthan'>
                <h2 className='stateHeading'>Rajasthan</h2>
                <div className="row g-0">

                    <div className="col-md-8">
                        <div className="card-body">

                            <div className="accordion" id="accordionExample7">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading31">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="true" aria-controls="collapse31" >
                                            Knitpro International
                                        </button>
                                    </h2>
                                    <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31" data-bs-parent="#accordionExample7">
                                        <div className="accordion-body">
                                            <p>Location: Jaipur</p>
                                            <p>Plant Capacity:  44KW</p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading32">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse32" aria-expanded="false" aria-controls="collapse32">
                                            PPAP Pathredi
                                        </button>
                                    </h2>
                                    <div id="collapse32" className="accordion-collapse collapse" aria-labelledby="heading32" data-bs-parent="#accordionExample7">
                                        <div className="accordion-body">
                                            <p>Location: Pathredi </p>
                                            <p>Plant Capacity: 1MW </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading33">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33">
                                            Renew Power
                                        </button>
                                    </h2>
                                    <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="heading33" data-bs-parent="#accordionExample7">
                                        <div className="accordion-body">
                                            <p>Location: Bikaner </p>
                                            <p>Plant Capacity: 250MW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading34">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse34" aria-expanded="false" aria-controls="collapse34">
                                        Solar Clean Energy
                                        </button>
                                    </h2>
                                    <div id="collapse34" className="accordion-collapse collapse" aria-labelledby="heading34" data-bs-parent="#accordionExample7">
                                        <div className="accordion-body">
                                            <p>Location:  Alwar </p>
                                            <p>Plant Capacity: 830KW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading35">
                                        <button className="accordion-button collapsed textState" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35" aria-expanded="false" aria-controls="collapse35">
                                        Azure Power
                                        </button>
                                    </h2>
                                    <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="heading35" data-bs-parent="#accordionExample7">
                                        <div className="accordion-body">
                                            <p>Location: Jodhpur </p>
                                            <p>Plant Capacity: 130MW  </p>
                                            <br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      {/* Slideshow Section */}
    <div className="col-md-4 d-flex align-items-center justify-content-center">
      <div className="w3-content w3-display-container">
        {images6.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`mySlides ${index === slideIndex6 ? 'active' : ''}`}
            style={{ width: '100%', maxWidth: '400px', borderRadius: '10px' }}
          />
        ))}

        <button className="w3-button w3-display-left" onClick={prevSlide6}>
          &#10094;
        </button>
        <button className="w3-button w3-display-right" onClick={nextSlide6}>
          &#10095;
        </button>

        <div className="dots-container">
          {images6.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === slideIndex6 ? 'active' : ''}`}
              onClick={() => showSlide6(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
                </div>
            </div>
        </>
    );
}
export default Project;
