// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formbg{
    background: transparent;
    color: rgb(250, 244, 244);
    border-radius: 30px;
    width: 45%;
    
    margin-left: 25%;
    -webkit-backdrop-filter: blur(60px);
            backdrop-filter: blur(60px);
}
@media(max-width:576px){
    .formbg{
        background: transparent;
        color: rgb(250, 244, 244);
        border-radius: 30px;
        width: 80%;
        margin-right: 20%;
        margin-left: 40px;
        -webkit-backdrop-filter: blur(60px);
                backdrop-filter: blur(60px);
        
    }
}`, "",{"version":3,"sources":["webpack://./src/application.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,UAAU;;IAEV,gBAAgB;IAChB,mCAA2B;YAA3B,2BAA2B;AAC/B;AACA;IACI;QACI,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB,UAAU;QACV,iBAAiB;QACjB,iBAAiB;QACjB,mCAA2B;gBAA3B,2BAA2B;;IAE/B;AACJ","sourcesContent":[".formbg{\r\n    background: transparent;\r\n    color: rgb(250, 244, 244);\r\n    border-radius: 30px;\r\n    width: 45%;\r\n    \r\n    margin-left: 25%;\r\n    backdrop-filter: blur(60px);\r\n}\r\n@media(max-width:576px){\r\n    .formbg{\r\n        background: transparent;\r\n        color: rgb(250, 244, 244);\r\n        border-radius: 30px;\r\n        width: 80%;\r\n        margin-right: 20%;\r\n        margin-left: 40px;\r\n        backdrop-filter: blur(60px);\r\n        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
