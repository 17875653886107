// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgcareer{
    
    background-size: cover;
    height: 300px;
    width: 101%;
}
.jobTitle{
text-align: left;
font-family: calibiri;
font-size: larger

}
/* @media(max-width:576px) */
   
.links{
    color: rgb(0, 145, 255);
}
.links:hover{
    color: rgb(38, 0, 255);
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Career.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;IACtB,aAAa;IACb,WAAW;AACf;AACA;AACA,gBAAgB;AAChB,qBAAqB;AACrB;;AAEA;AACA,4BAA4B;;AAE5B;IACI,uBAAuB;AAC3B;AACA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B","sourcesContent":[".imgcareer{\r\n    \r\n    background-size: cover;\r\n    height: 300px;\r\n    width: 101%;\r\n}\r\n.jobTitle{\r\ntext-align: left;\r\nfont-family: calibiri;\r\nfont-size: larger\r\n\r\n}\r\n/* @media(max-width:576px) */\r\n   \r\n.links{\r\n    color: rgb(0, 145, 255);\r\n}\r\n.links:hover{\r\n    color: rgb(38, 0, 255);\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
