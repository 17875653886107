import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import "./application.css";

const ApplicationForm = () => {
  const location = useLocation();
  const profile = location.state ? location.state.profile : null;
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    position: profile,
    cover_Letter: "",
    resume: null, // Change from "" to null
  });

  const [captcha, setCaptcha] = useState("");
  const [typedCaptcha, setTypedCaptcha] = useState("");
  const [message, setMessage] = useState("");
  const canvasRef = useRef(null);

  const generateCaptcha = () => {
    const canvas = canvasRef.current;
    const pen = canvas.getContext("2d");
    const captchaText = Math.random().toString(36).substring(2, 8);

    pen.clearRect(0, 0, canvas.width, canvas.height);
    pen.fillStyle = "#f0f0f0";
    pen.fillRect(0, 0, canvas.width, canvas.height);

    let captchaModified = captchaText;
    const maxLength = captchaText.length;
    const index1 = Math.floor(Math.random() * maxLength);
    const index2 = Math.floor(Math.random() * maxLength);

    if (index1 > 0) {
      captchaModified =
        captchaModified.substring(0, index1) +
        captchaModified[index1].toUpperCase() +
        captchaModified.substring(index1 + 1);
    }
    if (index2 > 0) {
      captchaModified =
        captchaModified.substring(0, index2) +
        captchaModified[index2].toUpperCase() +
        captchaModified.substring(index2 + 1);
    }

    setCaptcha(captchaModified);
    pen.font = "30px Arial";
    pen.fillStyle = "black";
    pen.fillText(captchaModified.split("").join(" "), 30, 40);

    pen.beginPath();
    pen.moveTo(20, 30);
    pen.lineTo(canvas.width - 20, 30);
    pen.strokeStyle = "red";
    pen.lineWidth = 2;
    pen.stroke();
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Handle file separately
    if (e.target.type === "file") {
      setFormData({ ...formData, resume: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const apply = async (e) => {
    e.preventDefault();

    if (typedCaptcha !== captcha) {
      setMessage("Captcha does not match. Please try again.");
      generateCaptcha();
      setTypedCaptcha("");
      return;
    }

    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("mobile", formData.mobile);
      data.append("email", formData.email);
      data.append("position", formData.position);
      data.append("cover_Letter", formData.cover_Letter);
      data.append("resume", formData.resume); // Append file

      const response = await fetch("https://www.skilancersolar.com:4000/datasubmit", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMessage("Application submitted successfully!");
      alert("Application submitted successfully!");

      setFormData({
        name: "",
        mobile: "",
        email: "",
        position: profile,
        cover_Letter: "",
        resume: null,
      });
      setTypedCaptcha("");
      generateCaptcha();
    } catch (err) {
      setMessage("Error in submission. Please try again.");
      console.error("Error in fetch:", err);
    }
  };

  return (
    <div className="maintop">
      <div className="main">
        <form className="apply" onSubmit={apply} encType="multipart/form-data">
          <h3 style={{ textAlign: "center" }}>
            <img
              height="60px"
              src="https://official-website-skilancersolar.s3.ap-south-1.amazonaws.com/Image/skilancerlogo1.png"
              alt="Logo"
            />
            <p style={{ fontSize: "27px", margin: "10px 0" }}>
              Application Form
            </p>
          </h3>

          <label>Name <span className="star">*</span></label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your full name" required />

          <label>Mobile <span className="star">*</span></label>
          <input type="number" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="+91 - "required />

          <label>Email <span className="star">*</span></label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your Email Address"required />

          <label>Position <span className="star">*</span></label>
          <input type="text" readOnly name="position" value={formData.position} placeholder="Applied for" required />

          <label>Cover Letter <span className="star">*</span></label>
          <textarea name="cover_Letter" minLength={50} style={{ backgroundColor: "#EEEEEE" }} rows="3" value={formData.cover_Letter} onChange={handleChange} placeholder="About you" required />

          <label>Resume  <span className="star">*</span></label>
          <input type="file" name="resume" accept=".pdf" onChange={handleChange} required  /> 

          <label>Captcha <span className="star">*</span></label>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <canvas ref={canvasRef} width="200" height="50"></canvas>
            <button type="button" onClick={generateCaptcha} style={{ background: "none", border: "none", cursor: "pointer" }}>
              <img height={30} src="https://static.vecteezy.com/system/resources/previews/002/261/105/non_2x/refresh-icon-symbol-sign-isolate-on-white-background-illustration-eps-10-free-vector.jpg" alt="Refresh Captcha" />
            </button>
          </div>
          <input type="text" value={typedCaptcha} onChange={(e) => setTypedCaptcha(e.target.value)} placeholder="Captcha" required />
          {message && <p style={{ color: "red", textAlign: "center" }}>{message}</p>}
          
          <button className="sub" type="submit" style={{ marginTop: "15px" }}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
